export default {
  namespaced: true,
  state: {
    selected_fees: [],
    payment_history: [],
    fees: [],
  },
  actions: {
    loadFees({ commit }) {
      this._vm.$api.get("payments/room-user-fees/").then((response) => {
        commit("setFees", response.data.results);
      });
    },
    pay({ state, getters, dispatch }) {
      const room_user_fees = [];
      state.selected_fees.forEach((selected_fee) =>
        room_user_fees.push(selected_fee.id)
      );
      return this._vm.$api
        .post("payments/room-fee-payment/", {
          room_user_fee: room_user_fees,
          amount: getters.total_amount,
        })
        .then((res) => {
          dispatch("fetchPaymentHistory");
          return res.data;
        })
        .then((res) => res);
    },
    fetchPaymentHistory({ commit }) {
      return this._vm.$api.get("payments/").then((response) => {
        commit("setPaymentHistory", response.data.results);
      });
    },
  },
  mutations: {
    setPaymentHistory(state, payment_history) {
      state.payment_history = payment_history;
    },
    setFees(state, payload) {
      state.fees = payload;
    },
  },
  getters: {
    total_amount: (state) => {
      return state.selected_fees.reduce((total, room_user_fee) => {
        return Number(total) + Number(room_user_fee.room_fee.fee.amount);
      }, 0);
    },
    fees: (state) => state.fees,
  },
};
