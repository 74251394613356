import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    notice: [],
  },

  //actions called in tab-notice
  actions: {
    get({ commit }) {
      return api.get("/notice/").then((response) =>
        commit(
          "load",
          response.data.results.map((data) => ({
            ...data,
            created_at: new Date(data.created_at).toLocaleString(),
          }))
        )
      );
    },
    add({ dispatch }, { title, content }) {
      return api
        .post("/notice/", {
          title,
         content,
          // posted_by: this.$store.getters["user/getUsername"],
        })
        .then(() => dispatch("get"));
    },
    update({ dispatch }, { title, content}) {
      return api
        .patch(`notice/${id}/`, {
          title,
          content,
        })
        .then(() => dispatch("get"));
    },
    delete({ dispatch }, id) {
      return api.delete(`notice/${id}/`).then(() => dispatch("get"));
    },
    getComments({ commit }, payload) {
      return api
        .get(`notice/${payload.id}/comments/`)
        .then((response) => {
          commit("loadComments", {
            id: payload.id,
            comments: response.data.results,
          });
        })
        .catch((err) => console.log(err));
    },
  },

  mutations: {
    load(state, payload) {
      state.notice = payload;
    },
    loadComments(state, payload) {
      state.notice.find((notice) => notice.id === payload.id).comments =
        payload.comments;
    },
  },
  getters: {
    getOrganizationDetails: (state) => state.organization,
    getAccessToken: (state) => state.access,
    getRefreshToken: (state) => state.refresh,
    getUsername: (state) => state.username,
    userIsAuthenticated: (state) => state.authenticated,
    getUserId: (state) => state.id,
    getName: (state) => state.name,
    getEmail: (state) => state.email,
    getUserType: (state) => state.user_type,
    isOrganization: (state) => state.user_type === "ORGANIZATION",
    getOrganizationID: (state) =>
      state.user_type === "ORGANIZATION" ? state.id : null,
    isRegular: (state) => state.user_type === "REGULAR",
    notice_for_current_class: (state) => (class_id) =>
      state.notice.filter((note) => note.notice == class_id),
      all_notice: (state) => state.notice,
  },
};
