<template>
  <v-footer color="primary" padless>
    <v-row justify="center" no-gutters class="pa-6">
      <v-col cols="12">
        <v-row justify="center" class="text-center white--text">
          <v-col sm="6" md="4" lg="2" class="px-0">
            <v-card-text class="px-0">
              <v-icon dark small class="hidden-sm-and-down">mdi-phone</v-icon>
              +88 09638 011 099
            </v-card-text>
          </v-col>
          <v-col sm="6" md="4" lg="2" class="px-0">
            <v-card-text class="px-0"
              ><v-icon dark small class="hidden-sm-and-down">mdi-email</v-icon>
              workshop@meetingme.live</v-card-text
            >
          </v-col>
        </v-row>
        <v-divider class="mx-4" dark></v-divider>
        <v-card-text class="text-center white--text">
          &copy; {{ new Date().getFullYear() }} — <strong>Meeting Me</strong>
        </v-card-text>
      </v-col>
      <!-- <v-col class="primary lighten-1 py-4 px-4 white--text" cols="12">
        <v-card-text>
          <v-icon dark small>mdi-phone </v-icon> +88 09638 011 099</v-card-text
        >
        <v-card-text class="text-center"
          ><v-icon dark small>mdi-email </v-icon>
          workshop@meetingme.live</v-card-text
        >
        {{ new Date().getFullYear() }} — <strong>Meeting Me</strong>
      </v-col> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
</style>