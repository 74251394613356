<template>
  <nav>
    <v-app-bar color="primary" dark>
      <router-link to="/">
        <v-img
          alt="meeting me Logo"
          src="@/assets/logo-meetingme.png"
          transition="scale-transition"
          height="50"
          contain
        ></v-img
      ></router-link>

      <v-spacer></v-spacer>

      <div class="hidden-md-and-down">
        <v-btn v-for="nav in generalMenus" :key="nav.title" :to="nav.path" text>
          <span class="mr-2">{{ nav.title }}</span>
        </v-btn>
      </div>

      <div v-if="loggedIn">
        <v-btn
          v-for="nav in loggedInUserNavMenus"
          :key="nav.title"
          :to="nav.path"
          text
          class="hidden-md-and-down"
        >
          {{ nav.title }}
        </v-btn>
        <v-btn
          @click="showCheckInDialog = true"
          v-if="roomUser.length > 1"
          color="warning"
        >
          {{ checkInTime ? "Check Out" : "Check In" }}
        </v-btn>
        <v-btn @click="onBtnClick" v-if="roomUser.length === 1" color="red">
          {{ buttonText }}
        </v-btn>
      </div>
      <div v-else>
        <v-btn v-for="nav in userNavMenus" :key="nav.title" :to="nav.path" text>
          {{ nav.title }}
        </v-btn>
      </div>

      <v-dialog
        v-if="!roomUser.length <= 1"
        v-model="showCheckInDialog"
        width="200"
        ref="checkInDialog"
      >
        <v-card>
          <v-card-title>{{
            checkInTime ? "Check Out" : `Check In - ${getTime(new Date())}`
          }}</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="selectedClass"
              :items="roomUser"
              item-text="room_name"
              item-value="id"
              label="Select Class"
              required
            >
            </v-autocomplete>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="handleCheckIn">{{
              checkInTime ? "Check Out" : "Check In"
            }}</v-btn>
            <v-btn @click="showCheckInDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        :hidden="sidebar"
        @click="sidebar = true"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="sidebar" app temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="nav in generalMenus.concat(loggedInUserNavMenus)"
            :key="nav.title"
            :to="nav.path"
            text
          >
            <span class="mr-2">{{ nav.title }}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavBar",

  data() {
    return {
      checkTime: null,
      checkoTime: null,
      sidebar: false,
      showCheckInDialog: false,
      selectedClass: [],
      appTitle: "Meeting Me",
      showDropdown: false,
      buttonText: "Check In",

      loggedInUserNavMenus: [
        // { title: "my-students", path: "/myStudent" },
        { title: "Notice", path: "/notice" },
        { title: "Exam board", path: "/examboard" },
        { title: "Profile", path: "/profile" },
        { title: "Logout", path: "/logout" },
      ],
      userNavMenus: [{ title: "Login", path: "/login" }],
      generalMenus_: [
        // { title: "Workshop", path: "/workshop" }
      ],
      // checkInTime: false,
      roomUser: [],
    };
  },

  computed: {
    ...mapGetters({
      username: "user/getName",
      loggedIn: "user/userIsAuthenticated",
      userType: "user/getUserType",
    }),
    generalMenus() {
      const menus = [...this.generalMenus_];
      if (["REGULAR", "STUDENT"].includes(this.userType)) {
        menus.push({
          title: "Payment",
          path: "/payment",
        });
      }
      if (["TEACHER", "ADMIN"].includes(this.userType)) {
        menus.push(
          { title: "students", path: "/myStudent" },
          { title: "billing", path: "/myBilling" }
        );
      }

      return menus;
    },
  },
  mounted() {
    this.classUsers();
  },
  methods: {
    onBtnClick() {
      const now = new Date();
      const checktime = now.toLocaleString();
      const data = {
        class_room: this.roomUser[0].room,
        name: this.roomUser[0].room_name,
      };
      if (this.checkInTime) {
        data.check_out = checktime;
        this.checkInTime = false;
        this.buttonText = "Check In";
        this.checkTime = new Date().toLocaleTimeString();
        alert(
          `You have checked out of the class ${data.name} at ${this.checkTime}`
        );
      } else {
        data.check_in = checktime;
        this.checkInTime = true;
        this.buttonText = "Check Out";
        this.checkTime = new Date().toLocaleTimeString();
        alert(
          `You have checked in to the class ${data.name} at ${this.checkTime}`
        );
      }
      this.$api.post("/check_attendance/", data);
    },

    handleLogout() {
      this.$store.commit("user/logout");
    },
    classUsers() {
      this.$api
        .get("/user_rooms/")
        .then((res) => (this.roomUser = res.data.results));
    },

    handleCheckIn() {
      if (!this.selectedClass) {
        alert("Please select a class.");
        return;
      }

      const now = new Date();
      const checktime = now.toLocaleString();

      const data = {
        class_room: this.selectedClass,
      };
      if (this.checkInTime) {
        data.check_out = checktime;
        this.checkInTime = false;
        this.showCheckInDialog = false;
        this.checkTime = new Date().toLocaleTimeString();
        alert(
          `You have checked in to the class ${data.class_room} at ${this.checkTime}`
        );
      } else {
        data.check_in = checktime;
        this.checkInTime = true;
        this.showCheckInDialog = false;
        this.checkoTime = new Date().toLocaleTimeString();
        alert(
          `You have checked in to the class ${data.class_room} at ${this.checkTime}`
        );
      }
      this.$api.post("/check_attendance/", data);
      this.$refs.checkInDialog.close();
    },
    getTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
      return formattedTime;
    },
  },
};
</script>

<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown ul {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #13d7b7;
  border: 1px solid #ccc;
  z-index: 999;
}

.dropdown ul li {
  padding: 10px;
}
.dropdown ul li:hover {
  background-color: #eee;
  cursor: pointer;
}

.dropdown ul li:hover {
  background-color: #2b13b1;
}
</style>
