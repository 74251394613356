import "core-js/stable";
import Vue from "vue";
import "./plugins/axios";
import App from "./App";
import router from "./router";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { store } from "@/store/index";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import api from "@/services/api";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import moment from "moment";
// import VueGtag from "vue-gtag";
// import VueFacebookPixel from 'vue-facebook-pixel';

// Vue.use(VueFacebookPixel)
// Vue.analytics.fbq.init('796048175372991', {
//   em: 'mailrony@gmail.com'
// })
// Vue.analytics.fbq.event('ViewContent', {
//   content_name: 'Really Fast Running Shoes'
// })

Vue.use(VueTelInputVuetify, {
  vuetify,
});
// Vue.use(
//   VueGtag,
//   {
//     config: { id: "G-8X2EGE6HQ3" },
//   },
//   router
// );
// Vue.use(
//   VueFacebookPixel,
//   {
//     id: "796048175372991",
//   },
//   router
// );

Vue.prototype.$requiredRules = [(v) => !!v || "This Field is Required"];
Vue.prototype.$phoneRules = [(v) => !!v || "Phone is required"];
Vue.prototype.$api = api;
Vue.prototype.$axios = api;
Vue.prototype.$copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};
Vue.prototype.moment = moment;
Vue.config.performance = true;

new Vue({
  el: "#app",
  template: "<App/>",
  vuetify,
  store,
  router,
  components: {
    App,
  },
  async created() {
    if (localStorage.getItem("access") && localStorage.getItem("refresh")) {
      this.$store.dispatch("user/validate").then(() => {
        this.$store.dispatch("meetings/get");
        this.$store.dispatch("assignments/get");
        this.$store.dispatch("exams/get");
        this.$store.dispatch("notes/get");
        this.$store.dispatch("notice/get");
        // this.$store.dispatch("examboard/get");
        this.$store.dispatch("classes/get");
      });
    }
  },
}).$mount();
