import Axios from "axios";
// const base_url = "https://apps.livetutor.com.bd/api/";

const base_url =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_BACKEND_BASEURL
    : process.env.VUE_APP_BACKEND_BASEURL_DEV;
let config = {
  baseURL: base_url,
};

const api = Axios.create(config);

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
