import Vue from "vue";
import Router from "vue-router";
import { store } from "@/store";
const Page404 = () => import("@/pages/Page404");
const Page500 = () => import("@/pages/Page500");

const Login = () => import("@/pages/Login");
const Register = () => import("@/pages/Register");
const Logout = () => import("@/pages/Logout");

const CompleteProfile = () => import("@/components/register/CompleteProfile");
const Home = () => import("@/pages/Home");
const Assignments = () => import("@/pages/Assignment");
const Exams = () => import("@/pages/Exam");

const Notes = () => import("@/pages/Note");

Vue.use(Router);

let router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
// const requiresTeacher = to.matched.some(record => record.meta.requiresTeacher)
// const requiresStudent = to.matched.some(record => record.meta.requiresStudent)

//   if (requiresAuth) {
//     if (!store.getters["user/userIsAuthenticated"]) {
//       next({ name: "Login" })
//       return
//     }

//     if (requiresTeacher && !store.getters["user/isTeacher"]) {
//       next({ name: "404" })
//       return
//     }
//     if (requiresStudent && !store.getters["user/isRegular"]) {
//       next({ name: "404" })
//       return
//     }
//   }

//   next()
// })

// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     !store.getters["user/userIsAuthenticated"]
//   ) {
//     store
//       .dispatch("user/validate")
//       .then(() => next())
//       .catch(() => next({ name: "Login" })); //work
//   } else {
//     next();
//   }
// });

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.authNotRequired)) {
    next();
  } else if (!store.getters["user/userIsAuthenticated"]) {
    store
      .dispatch("user/validate")
      .then(() => next())
      .catch(() => next({ name: "Login" }));
  } else {
    if (store.getters["user/userIsAuthenticated"]) {
      if (store.getters["user/isAdmin"]) {
        next();
        return;
      }
      if (to.meta.requiresTeacher && !store.getters["user/isTeacher"]) {
        next({ name: "403" });
        return;
      }
      if (to.meta.requiresStudent && !store.getters["user/isRegular"]) {
        next({ name: "403" });
        return;
      }
      next();
    }
  }
});

export default router;

function configRoutes() {
  return [
    {
      path: "/JoinMeeting",
      name: "JoinMeeting",
      component: () => import("@/pages/JoinMeeting"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/ResetPassword",
      name: "reset-password",
      component: () => import("@/pages/ResetPassword"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/meeting/:lobbyName",
      name: "meeting",
      props: true,
      component: () => import("@/pages/Meeting"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/organization/manage",
      name: "ManageOrganization",
      component: () => import("@/pages/organization/manage/index.vue"),
      children: [
        {
          path: "/organization/manage/add-organization-users",
          name: "AddOrganizationUsers",
          component: () =>
            import(
              "@/components/organization/create/UploadOrganizationUsersRooms.vue"
            ),
        },
        {
          path: "/organization/manage/update-organization-details",
          name: "UpdateOrganizationDetails",
          component: () =>
            import("@/pages/organization/manage/UpdateOrganizationDetails.vue"),
        },

        {
          path: "/organization/manage/manage-organization-fees",
          name: "ManageOrganizationFees",
          component: () =>
            import("@/pages/organization/manage/ManageOrganizationFees.vue"),
        },
        {
          path: "/organization/manage/organization-users",
          name: "OrganizationUsers",
          component: () =>
            import("@/pages/organization/manage/OrganizationUsers"),
        },
        {
          path: "/organization/payment/payment-summary/",
          name: "PaymentSummary",
          component: () =>
            import("@/pages/organization/payment/PaymentSummary.vue"),
        },
        {
          path: "/organization/payment/fee-heads/",
          name: "FeeHeads",
          component: () => import("@/pages/organization/payment/FeeHeads.vue"),
        },
        {
          path: "/organization/payment/report/individual/:user_id",
          name: "FeeHeads",
          component: () =>
            import("@/pages/organization/payment/IndividualDetailed.vue"),
        },

        {
          path: "/organization/notice/list/",
          name: "NoticeList",
          component: () => import("@/pages/organization/notice/index.vue"),
        },
        {
          path: "/organization/notice/new/",
          name: "NewNotice",
          component: () => import("@/pages/organization/notice/NewNotice.vue"),
        },
        {
          path: "/organization/examboard/list/",
          name: "ExamList",
          component: () => import("@/pages/organization/exam/index.vue"),
        },
        {
          path: "/organization/examboard/new/",
          name: "NewExam",
          component: () => import("@/pages/organization/exam/NewExam.vue"),
        },

        {
          path: "/organization/users/",
          name: "UserList",
          component: () => import("@/pages/organization/users/index.vue"),
        },
        {
          path: "/organization/users/add-users/",
          name: "AddUsers",
          component: () => import("@/pages/organization/users/AddUsers.vue"),
        },
        {
          path: "/organization/users/single-user-entry/",
          name: "AddUsers",
          component: () =>
            import("@/pages/organization/users/SingleUserEntry.vue"),
        },
        {
          path: "/organization/room/list/",
          name: "RoomList",
          component: () => import("@/pages/organization/room/index.vue"),
        },
        {
          path: "/organization/room/:id",
          name: "RoomManage",
          component: () => import("@/pages/organization/room/_Room.vue"),
        },
        {
          path: "/organization/sms/compose/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/index.vue"),
        },
        {
          path: "/organization/sms/list/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/Report.vue"),
        },
        {
          path: "/organization/sms/template/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/TemplateForm.vue"),
        },
        {
          path: "/organization/sms/draft/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/DraftView.vue"),
        },
        {
          path: "/organization/sms/schedule/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/Schedule.vue"),
        },
        {
          path: "/organization/sms/buySms/",
          name: "SMS",
          component: () => import("@/pages/organization/sms/BuySMS.vue"),
        },
        {
          path: "/organization/attendance/list/",
          name: "Attendance",
          component: () => import("@/pages/organization/attendance/index.vue"),
        },
        {
          path: "/organization/attendance/show/",
          name: "Attendance",
          component: () =>
            import("@/pages/organization/attendance/ShowAttend.vue"),
        },
        {
          path: "/organization/attendance/showDetail/",
          name: "Attendance",
          component: () =>
            import("@/pages/organization/attendance/ShowAttendDetail.vue"),
        },
        {
          path: "/organization/meeting/generateToken/",
          name: "GenerateToken",
          component: () =>
            import("@/pages/organization/meeting/GenerateToken.vue"),
        },
        {
          path: "/organization/billing/adminBilling/",
          name: "Instructor Billing",
          component: () => import("@/pages/batchstudent/AdminBilling.vue"),
        },
      ],
    },

    {
      path: "/payment",
      name: "payment",
      component: () => import("@/pages/Payment"),
    },
    {
      path: "/rooms/:id/fees",
      name: "fees",
      component: () => import("@/pages/Fees"),
    },
    {
      path: "/notice",
      name: "notice",
      component: () => import("@/pages/notice/index"),
    },

    {
      path: "/notice/:id",
      name: "NoticeDetails",
      component: () => import("@/pages/notice/Details"),
    },
    {
      path: "/complete",
      name: "complete_profile",
      component: CompleteProfile,
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/pages/profile/index"),
      children: [
        {
          path: "/profile",
          name: "Dashboard",
          component: () => import("@/pages/profile/Dashboard"),
        },
        {
          path: "/profile/update",
          name: "UpdateProfile",
          component: () => import("@/pages/profile/UpdateProfile"),
        },
      ],
    },
    {
      path: "/class-room/:id",
      name: "room",
      component: () => import("@/pages/room/index.vue"),
    },
    {
      path: "/exam/:id",
      name: "exams",
      component: Exams,
    },
    {
      path: "/assignments/:id",
      name: "assignments",
      component: Assignments,
    },

    {
      path: "/notes/:id",
      name: "notes",
      component: Notes,
    },
    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/workshop/index"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/examboard",
      name: "examboard",
      component: () => import("@/pages/notice/Examindex"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      meta: {
        authNotRequired: true,
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
          meta: {
            authNotRequired: true,
          },
        },
        {
          path: "403",
          name: "Page403",
          component: () => import("@/pages/Page403"),
          meta: {
            authNotRequired: true,
          },
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
          meta: {
            authNotRequired: true,
          },
        },
      ],
    },
    {
      path: "/",
      redirect: "/login",
      name: "Auth",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
          meta: {
            authNotRequired: true,
          },
        },
        {
          path: "register",
          name: "Register",
          component: Register,
          meta: {
            authNotRequired: true,
          },
        },
      ],
    },
    // {
    //   path: "/workshop",
    //   name: "workshop",
    //   component: () => import("@/pages/workshop/index"),
    //   meta: {
    //     authNotRequired: true,
    //   },
    // },
    {
      path: "/workshop/:slug",
      name: "workshopDetails",
      component: () => import("@/pages/workshop/Details"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/myStudent/",
      name: "myStudent",
      component: () => import("@/pages/batchstudent/MyStudent"),
      meta: {
        requiresTeacher: true,
      },
    },
    {
      path: "/event/",
      name: "Event",
      component: () => import("@/pages/event/index"),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/myBilling/",
      name: "myBilling",
      component: () => import("@/pages/batchstudent/Billing"),
      meta: {
        requiresTeacher: true,
      },
    },
    {
      path: "/adminBilling/",
      name: "adminBilling",
      component: () => import("@/pages/batchstudent/AdminBilling"),
    },
    {
      path: "*",
      name: "404",
      component: Page404,
      meta: {
        authNotRequired: true,
      },
    },
  ];
}
