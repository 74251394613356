import api from "@/services/api";
export default {
  namespaced: true,
  state: {
    meetings: [],
  },
  actions: {
    get({ commit }) {
      return api.get("/meetings/room-subject-meetings/").then((response) =>
        commit(
          "load",
          response.data.results.map((room_subject_meeting) => ({
            ...room_subject_meeting,
            starts_at: new Date(
              `${room_subject_meeting.meeting.date} ${room_subject_meeting.meeting.start_time}`
            ),
            ends_at: new Date(
              `${room_subject_meeting.meeting.date} ${room_subject_meeting.meeting.end_time}`
            ),
            timed: true,
          }))
        )
      );
    },

    add({ dispatch }, { room_subject, meetings }) {
      return new Promise((resolve, reject) => {
        api
          .post("/meetings/room-subject-meetings/", { room_subject, meetings })
          .then((response) => {
            dispatch("get").then(() => {
              resolve(response);
            });
          })
          .catch((error) => reject(error));
      });
    },
    update(
      { dispatch },
      { id, name, description, date, start_time, end_time }
    ) {
      return api
        .patch(`/meetings/room-subject-meetings/${id}/`, {
          id,
          name,
          description,
          date,
          start_time,
          end_time,
        })
        .then(() => dispatch("get"));
    },
    delete({ dispatch }, { id }) {
      return api
        .delete(`/meetings/room-subject-meetings/${id}/`)
        .then(() => dispatch("get"));
    },
  },
  mutations: {
    load(state, payload) {
      state.meetings = payload;
    },
  },
  getters: {
    upcoming_meetings: (state) =>
      state.meetings.filter(
        (meeting) =>
          new Date(meeting.starts_at).getTime() >= new Date().getTime()
      ),
    meetings_for_current_class: (state) => (class_id, trial) =>
      state.meetings.filter(
        (meeting) => meeting.room === class_id && meeting.trial == trial
      ),
  },
};
